@import '../../styles/helpers/index.scss';

.home {
  height: inherit;

  &__inner {
    height: inherit;

    @include flex-centering;
    flex-direction: column;

    p {
      margin: 45px 0 30px;
      font-size: 36px;
      text-align: center;
      text-transform: uppercase;

      @include tablet {
        margin: 30px 0;
        font-size: 24px;
      }
    }

    ul {
      li {
        position: relative;
        text-indent: 20px;

        @include tablet {
          text-indent: 15px;
        }

        &::before {
          @include pseudo-size(10px, 10px);
          @include absolute-centering;

          background-color: map-get($colors, 'text');
          border-radius: 50%;

          @include tablet {
            @include square(5px);
          }
        }

        a {
          text-decoration: none;
          font-size: 22px;
          color: map-get($colors, 'text');

          @include tablet {
            font-size: 18px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
