@import '../../styles/helpers/index.scss';

.buttons {
  display: none;

  position: fixed;
  z-index: 100;
  left: 0;
  width: 100%;
  height: 60px;

  background: #fff;
  box-shadow: 0px 0px 10px 3px rgb(7 7 7 / 50%);

  transition: all 0.3s ease;

  a {
    @include flex-centering;
    max-width: 50%;
    width: 100%;
    font-weight: bold;

    color: #fff;
    text-decoration: none;

    &:first-of-type {
      background-color: map-get($colors, 'primary');
    }

    &:last-of-type {
      background-color: map-get($colors, 'secondary');
      color: map-get($colors, 'text');
    }
  }

  &.off {
    bottom: -60px;
  }

  &.on {
    bottom: 0;
  }

  @include tablet {
    display: flex;
  }
}
