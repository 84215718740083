@use 'scss-reset/reset';

@import './helpers/variables.scss';
@import './helpers/mixins.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  color: map-get($colors, 'text');
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Nunito', sans-serif;

  overflow-x: hidden;
}

#root {
  height: inherit;

  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;

    padding-top: 120px;

    @media screen and (max-width: $tablet-sm) {
      padding-top: 85px;
    }

    section {
      &:not(:first-of-type) {
        margin: 120px 0 0;

        @include tablet {
          margin: 60px 0 0;
        }
      }
    }
  }
}
