@import '../../styles/helpers/index.scss';

.logo {
  @include align-center;
  @include gap-horizontal(15px);

  text-decoration: none;
  color: map-get($colors, 'text');

  &__icon {
    font-size: 48px;

    @include tablet {
      font-size: 42px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 24px;

    @include tablet {
      font-size: 18px;
    }

    span {
      letter-spacing: 3px;
    }
  }
}
