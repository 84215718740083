@import '../../styles/helpers/index.scss';

.loader {
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: spinner 1.1s infinite ease;
  animation: spinner 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  @media screen and (max-width: $tablet-sm) {
    font-size: 15px;
  }

  &-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@-webkit-keyframes spinner {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.5),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes spinner {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.5),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.8em -1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      2.5em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      1.75em 1.75em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      0em 2.5em 0 0em rgba(map-get($colors, 'secondary'), 0.2),
      -1.8em 1.8em 0 0em rgba(map-get($colors, 'secondary'), 0.5),
      -2.6em 0em 0 0em rgba(map-get($colors, 'secondary'), 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
